import * as links from './router/links';
import enums from './services/helpers/enums';

export default {
	items: [
		{
			title: 'Domov',
			routeName: links.homeName,
			href: links.DEFAULT,
			requiredPermission: enums.role.Operator,
			entityType: enums.remarkEntityType.Dashboard,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'chart-line', // 'fa fa-chart-line'
					'fixed-width': 'fixed-width',
				},
			},
		},

		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},

		{
			titleI18N: 'objects',
			routeName: links.objectsTableName,
			href: links.objectsTable,
			requiredPermission: enums.role.Operator,
			entityType: enums.remarkEntityType.Object,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'house-user', // 'fa fa-house-user'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'invoices',
			routeName: links.invoicesTableName,
			href: links.invoicesTable,
			requiredPermission: enums.role.Operator,
			entityType: enums.remarkEntityType.Invoice,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'file-alt', // 'fa fa-file-alt'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'contacts',
			routeName: links.contactsTableName,
			href: links.contactsTable,
			requiredPermission: enums.role.Operator,
			entityType: enums.remarkEntityType.Contact,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'address-card', // 'fa fa-address-card'
					'fixed-width': 'fixed-width',
				},
			},
		},

		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},

		{
			titleI18N: 'advertisements',
			routeName: links.objectDemandTableName,
			href: links.objectDemandTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'paper-plane', // 'fa fa-paper-plane'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'reservations2925',
			routeName: links.objectReservationTableName,
			href: links.objectReservationTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'calendar-alt', // 'fa fa-calendar-alt'
					'fixed-width': 'fixed-width',
				},
			},
		},

		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},

		{
			titleI18N: 'backlinks',
			routeName: links.backlinksTableName,
			href: links.backlinksTable,
			requiredPermission: enums.role.Operator,
			entityType: enums.remarkEntityType.Backlink,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'link', // 'fa fa-link'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'attractions2704',
			routeName: links.attractionTableName,
			href: links.attractionTable,
			requiredPermission: enums.role.CopyWriterJunior,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': ['fab', 'fort-awesome'], // 'fab fa-fort-awesome'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'seopages',
			routeName: links.seoPageTableName,
			href: links.seoPageTable,
			requiredPermission: enums.role.CopyWriterSenior,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'file-contract', // 'fa fa-file-contract'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'urlcatalog',
			routeName: links.urlCatalogTableName,
			href: links.urlCatalogTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'globe', // 'fa fa-globe'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},
		{
			titleI18N: 'objectratings',
			routeName: links.objectReviewsTableName,
			href: links.OBJECTREVIEWS,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'comments', // 'fa fa-comments'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'portalratings',
			routeName: links.portalRatingsTableName,
			href: links.portalRatings,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'hand-holding-heart', // 'fa fa-hand-holding-heart'
					'fixed-width': 'fixed-width',
				},
			},
		},

		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},

		{
			titleI18N: 'emails2982',
			routeName: links.emailsTemplatesTableName,
			href: links.emailsTemplatesTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'envelope', // 'fa fa-envelope'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'emailer',
			routeName: links.emailerTableName,
			href: links.emailerTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'mail-bulk', // 'fa fa-mail-bulk'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},
		{
			titleI18N: 'processes',
			routeName: links.portalJobRunsTableName,
			href: links.portalJobRunsTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'layer-group', // 'fa fa-layer-group'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			titleI18N: 'emaillogs',
			routeName: links.emailsLogsTableName,
			href: links.emailsLogsTable,
			requiredPermission: enums.role.Operator,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'code', // 'fa fa-code'
					'fixed-width': 'fixed-width',
				},
			},
		},

		{
			component: 'hr',
			requiredPermission: enums.role.Operator,
		},

		{
			titleI18N: 'dictionary',
			routeName: links.translationTableName,
			href: links.translationTable,
			requiredPermission: enums.role.TeamLeader,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'book', // 'fa fa-book'
					'fixed-width': 'fixed-width',
				},
			},
		},
		{
			component: 'hr',
			requiredPermission: enums.role.TeamLeader,
		},
		{
			titleI18N: 'areas',
			requiredPermission: enums.role.TeamLeader,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'map-marker-alt', // 'fa fa-map-marker-alt'
					'fixed-width': 'fixed-width',
				},
			},
			child: [
				{
					titleI18N: 'regions',
					routeName: links.regionTableName,
					href: links.regionTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'map-signs', // 'fa fa-map-signs'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'cities',
					routeName: links.cityTableName,
					href: links.cityTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'city', // 'fa fa-city'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'districts',
					routeName: links.districtTableName,
					href: links.districtTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'map-marked-alt', // 'fa fa-map-marked-alt'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'localities',
					routeName: links.territoryTableName,
					href: links.territoryTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'map', // 'fa fa-map'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'countries3376',
					routeName: links.countryTableName,
					href: links.countryTable,
					requiredPermission: enums.role.Admin,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': ['fas', 'flag'], // 'fas fa-flag'
							'fixed-width': 'fixed-width',
						},
					},
				},
			],
		},

		{
			titleI18N: 'invoicing',
			requiredPermission: enums.role.TeamLeader,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'file-invoice', // 'fa fa-file-invoice'
					'fixed-width': 'fixed-width',
				},
			},
			child: [
				{
					titleI18N: 'packages',
					routeName: links.productTableName,
					href: links.productTable,
					requiredPermission: enums.role.TeamLeader,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'coins', // 'fa fa-coins'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'companies',
					routeName: links.accountingCompanyTableName,
					href: links.accountingCompanyTable,
					requiredPermission: enums.role.Admin,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': ['fab', 'sketch'], // 'fab fa-sketch'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'reinvoicing',
					routeName: links.reinvoicingDetailName,
					href: links.reinvoicingDetail,
					requiredPermission: enums.role.Admin,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'file-invoice', // 'fa fa-file-invoice'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'exportinvoices',
					routeName: links.accountingExportDetailName,
					href: links.accountingExportDetail,
					requiredPermission: enums.role.Admin,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': ['fas', 'file-export'], // 'fas fa-file-export'
							'fixed-width': 'fixed-width',
						},
					},
				},
			],
		},

		{
			titleI18N: 'datalists',
			requiredPermission: enums.role.TeamLeader,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'list', // 'fa fa-list'
					'fixed-width': 'fixed-width',
				},
			},
			child: [
				{
					titleI18N: 'sojourntypes',
					routeName: links.sojournTypeTableName,
					href: links.sojournTypesTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'skiing', // 'fa fa-skiing'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'typesofau',
					routeName: links.accomodationUnitTypeTableName,
					href: links.accomodationUnitTypeTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'door-open', // 'fas fa-door-open'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'objectpropertie',
					routeName: links.objectSimplePropertyTableName,
					href: links.objectSimplePropertyTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'list-ol', // 'fas fa-list-ol'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'objecttypes',
					routeName: links.objectTypesTableName,
					href: links.objectTypesTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'house-user', // 'fas fa-house-user'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'catalogsections',
					routeName: links.catalogSectionTableName,
					href: links.catalogSectionTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'home', // 'fas fa-home'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'sectionsintheme',
					routeName: links.sectionMenuTableName,
					href: links.sectionMenuTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'laptop-house', // 'fas fa-laptop-house'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'groupsofattract',
					routeName: links.attractionGroupTableName,
					href: links.attractionGroupTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': ['fab', 'fort-awesome'], // 'fab fa-fort-awesome'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'seasons',
					routeName: links.priceListSeasonTableName,
					href: links.priceListSeasonTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'umbrella-beach', // 'fas fa-umbrella-beach'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'typesofprices',
					routeName: links.priceListUnitTableName,
					href: links.priceListUnitTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'tags', // 'fas fa-tags'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'categoriesofdis',
					routeName: links.discountCategoryTableName,
					href: links.discountCategoryTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'percent', // 'fas fa-percent'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'typesofdiscount',
					routeName: links.discountTypeTableName,
					href: links.discountTypeTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'percentage', // 'fas fa-percentage'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'cashcurrencies',
					routeName: links.currencyTableName,
					href: links.currencyTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': 'euro-sign', // 'fas fa-euro-sign'
							'fixed-width': 'fixed-width',
						},
					},
				},
				{
					titleI18N: 'faqgoogle',
					routeName: links.faqTableName,
					href: links.faqTable,
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': ['fab', 'google'], // 'fab fa-google'
							'fixed-width': 'fixed-width',
						},
					},
				},
			],
		},

		{
			titleI18N: 'statistics3353',
			requiredPermission: enums.role.Owner,
			icon: {
				element: 'font-awesome-icon',
				attributes: {
					'icon': 'chart-line',
					'fixed-width': 'fixed-width',
				},
			},
			child: [],
		},
	],
};
