var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-switcher" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            size: "lg",
            variant: "link",
            "toggle-class":
              "text-decoration-none d-flex align-items-center toggler",
          },
        },
        [
          _c("template", { slot: "button-content" }, [
            _c("span", [_vm._v(_vm._s(_vm.languagesName[_vm.selectedCode]))]),
            _c("label", [_vm._v(" ")]),
            _c("img", {
              attrs: {
                src: _vm.languagesImage[_vm.selectedCode],
                alt: _vm.selectedCode,
              },
            }),
          ]),
          _vm._l(_vm.languagesCode, function (langCode) {
            return _c(
              "b-dropdown-item",
              {
                key: langCode,
                on: {
                  click: function ($event) {
                    return _vm.changeLang(langCode)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.languagesName[langCode]))]),
                _c("img", {
                  attrs: { src: _vm.languagesImage[langCode], alt: langCode },
                }),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: {
            size: "lg",
            variant: "link",
            title: _vm.$t("selectcountry"),
            "toggle-class":
              "text-decoration-none d-flex align-items-center toggler",
          },
        },
        [
          _c("template", { slot: "button-content" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.countriesName[_vm.selectedCountry])),
            ]),
            _c("label", [_vm._v(" ")]),
            _c("img", {
              attrs: {
                src: _vm.countryImage[_vm.selectedCountry],
                alt: _vm.selectedCountry,
              },
            }),
          ]),
          _vm._l(_vm.countryCode, function (countryCode) {
            return _c(
              "b-dropdown-item",
              {
                key: countryCode,
                on: {
                  click: function ($event) {
                    return _vm.changeCountry(countryCode)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.countriesName[countryCode]))]),
                _c("img", {
                  attrs: {
                    src: _vm.countryImage[countryCode],
                    alt: countryCode,
                  },
                }),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }