<template>
	<div class="d-flex align-items-center">
		<span v-if="user.Login" class="pr-2 login-name">
			<strong>{{ $t('welcome') }}</strong>
			{{ user.Login }}
		</span>
		<font-awesome-icon class="pointer" icon="sign-out-alt" size="lg" @click="logoutfnc"></font-awesome-icon>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as links from '../.././router/links.js';
import auth from '../../services/auth.service';

export default {
	components: {
		FontAwesomeIcon,
	},
	name: 'Account',
	computed: {
		user() {
			return auth.GetUser();
		},
	},
	methods: {
		logoutfnc: function() {
			auth.Logout();
			this.$router.push(links.LOGIN);
		},
	},
};
</script>
<style lang="scss">
.login-name {
	margin-right: 5px;
}
</style>
