<template>
	<div id="quickSearch">
		<div class="d-flex flex-row ml-3 mr-3">
			<input
				:placeholder="$t('object2620')"
				type="text"
				:class="inputClass"
				v-model="objectsFilter"
				v-on:keyup.enter="filterObjects"
			/>
			<input
				:placeholder="$t('invoices2621')"
				type="text"
				:class="inputClass"
				v-model="invoicesFilter"
				v-on:keyup.enter="filterInvoices"
			/>
			<input
				:placeholder="$t('contacts2622')"
				type="text"
				:class="inputClass"
				v-model="contactsFilter"
				v-on:keyup.enter="filterContacts"
			/>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import * as links from '../../router/links.js';
import ObjectTable from '@/modules/objects/object.table';
import ContactsTable from '@/modules/contacts/contacts.table';
import InvoiceTable from '@/modules/invoices/invoice.table';

export default {
	data() {
		return {
			objectsFilter: '',
			invoicesFilter: '',
			contactsFilter: '',
			inputClass: 'ml-2 mr-2 form-control',
		};
	},
	methods: {
		filterObjects() {
			this.setQuickFilter(ObjectTable.name, this.objectsFilter);

			if (this.$route.name !== links.objectsTableName) {
				router.push({ name: links.objectsTableName });
			}

			this.objectsFilter = '';
		},
		filterContacts() {
			this.setQuickFilter(ContactsTable.name, this.contactsFilter);

			if (this.$route.name !== links.contactsTableName) {
				router.push({ name: links.contactsTableName });
			}

			this.contactsFilter = '';
		},
		filterInvoices() {
			this.setQuickFilter(InvoiceTable.name, this.invoicesFilter);

			if (this.$route.name !== links.invoicesTableName) {
				router.push({ name: links.invoicesTableName });
			}

			this.invoicesFilter = '';
		},
		setQuickFilter(tableName, value) {
			this.$store.commit('SET_QUICK_FILTER', {
				filter: tableName,
				value,
			});
		},
	},
};
</script>
