var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-menu",
    {
      attrs: { menu: _vm.sidebarItems, width: "200px", widthCollapsed: "37px" },
      on: {
        "toggle-collapse": function ($event) {
          return _vm.$emit("toggle-collapse", $event)
        },
      },
    },
    [
      _c("font-awesome-icon", {
        attrs: { slot: "toggle-icon", icon: "arrows-alt-h" },
        slot: "toggle-icon",
      }),
      _c("font-awesome-icon", {
        attrs: { slot: "dropdown-icon", icon: "angle-right" },
        slot: "dropdown-icon",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }