<template>
	<sidebar-menu :menu="sidebarItems" @toggle-collapse="$emit('toggle-collapse', $event)" width="200px" widthCollapsed="37px">
		<font-awesome-icon slot="toggle-icon" icon="arrows-alt-h"></font-awesome-icon>
		<font-awesome-icon slot="dropdown-icon" icon="angle-right"></font-awesome-icon>
	</sidebar-menu>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import http from '@/services/helpers/http';
import auth from '../../services/auth.service';
import enums from '../../services/helpers/enums';
import serviceCommon from '../../services/service/common.service';
import api from '../../services/api/dictionary.api';
import { loadRemarksCount } from '../../store/actions';

import { SidebarMenu } from 'vue-sidebar-menu';

export default {
	name: 'app-sidebar',

	components: {
		FontAwesomeIcon,
		SidebarMenu,
	},

	props: {
		sidebarList: { type: Array },
	},

	mounted() {
		if (auth.IsAllowedForUser(enums.role.Operator)) {
			this.$store.dispatch(loadRemarksCount);
		}
	},

	computed: {
		sidebarItems() {
			return this.transformMenuItems(this.sidebarList);
		},
		remarksObjectsCount() {
			return this.$store.state.remarksCount.remarksObjectsCount;
		},
		remarksInvoicesCount() {
			return this.$store.state.remarksCount.remarksInvoicesCount;
		},
		remarksContactsCount() {
			return this.$store.state.remarksCount.remarksContactsCount;
		},
		remarksBacklinksCount() {
			return this.$store.state.remarksCount.remarksBacklinksCount;
		},
		dashboardState() {
			return this.$store.state.dashboardState.status;
		},
	},

	methods: {
		pushRouter(routeName) {
			if (this.$router.currentRoute.name !== routeName) {
				this.$router.push({
					name: routeName,
				});
			}
		},

		transformMenuItems(items) {
			return items.map((i) => this.transformMenuItem(i));
		},
		transformMenuItem(item) {
			item.title = item.titleI18N ? this.$t(item.titleI18N) : item.title;

			if (item.entityType !== undefined) {
				const badge = this.itemBadge(item.entityType);
				if (badge && badge.text) {
					item.badge = badge;
				}
			}

			if (item.child) {
				item.child = this.transformMenuItems(item.child);
			}

			return item;
		},
		itemBadge(entityType) {
			let badge = null;
			switch (entityType) {
				case enums.remarkEntityType.Dashboard:
					badge = dashboardBadge(this.dashboardState);
					break;
				case enums.remarkEntityType.Object:
					badge = {
						text: this.remarksObjectsCount,
						class: 'vsm--badge_default',
					};
					break;
				case enums.remarkEntityType.Contact:
					badge = {
						text: this.remarksContactsCount,
						class: 'vsm--badge_default',
					};
					break;
				case enums.remarkEntityType.Invoice:
					badge = {
						text: this.remarksInvoicesCount,
						class: 'vsm--badge_default',
					};
					break;
				case enums.remarkEntityType.Backlink:
					badge = {
						text: this.remarksBacklinksCount,
						class: 'vsm--badge_default',
					};
					break;
			}

			return badge;

			function dashboardBadge(dashboardState) {
				switch (dashboardState) {
					case 2:
						return {
							text: '?',
							class: 'vsm--badge_default warnings font-weight-bold',
						};
					case 3:
						return {
							text: '!',
							class: 'vsm--badge_default font-weight-bold',
						};
					default:
						return null;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
