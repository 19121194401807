<template>
	<div class="app">
		<app-header />
		<div class="app-body">
			<app-sidebar class="sidebar" :sidebarList="nav" @toggle-collapse="isSidebarCollapsed = $event"></app-sidebar>
			<main :class="['main', { 'main-sidebar-collapsed': isSidebarCollapsed }]">
				<b-container fluid class="container-fluid">
					<!-- <quick-filter /> -->
					<router-view></router-view>
					<app-modal />
				</b-container>
			</main>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import nav from '../_nav';
import appSidebar from '../components/common/app-sidebar';
import appHeader from '../components/common/app-header';
import quickFilter from '../components/common/quick-filter';
import auth from '../services/auth.service';
import enums from '../services/helpers/enums';
import { StatisticService } from '../modules/statistics/services/statistic.service';
import * as links from '../router/links';

export default {
	name: 'defaultContainer',

	components: {
		appSidebar,
		appHeader,
		// quickFilter,
	},

	data() {
		return {
			isSidebarCollapsed: false,
			statistics: null,
		};
	},

	async created() {
		if(auth.IsAllowedForUser(enums.role.Owner)) {
			this.statistics = await StatisticService.getStatistics();
		}
	},

	computed: {
		nav() {
			this.appendStatisticsMenu(nav.items);
			return this.filterByPermission(nav.items);
		},

		loaded() {
			return this.$store.state.country.loaded;
		},

		name() {
			return this.$route.name;
		},

		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},
	},

	methods: {
		appendStatisticsMenu(items) {
			if (!this.statistics) return;
			const statsMenuItem = items.find((i) => i.titleI18N === 'statistics3353');
			if (statsMenuItem) {
				statsMenuItem.child = transformItems(this.statistics);
			}

			function transformItems(statistics) {
				return statistics.map((item) => transformItem(item));
			}

			function transformItem(item) {
				return {
					titleI18N: item.Text,
					routeName: links.statisticsPageName,
					href: links.statisticsPageWithParam(item.Id),
					icon: {
						element: 'font-awesome-icon',
						attributes: {
							'icon': item.Icon,
							'fixed-width': 'fixed-width',
						},
					},
				};
			}
		},

		filterByPermission(items) {
			return items.filter((route) => {
				// check if user has permission for menu item
				const hasPermission = !route.requiredPermission || auth.IsAllowedForUser(route.requiredPermission);
				if (!hasPermission) {
					return false;
				}

				// check if user has permission for children menu items
				if (route.child) {
					route.child = this.filterByPermission(route.child);
					if (!route.child.length) {
						return false;
					}
				}

				return true;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.app-body {
	margin-top: 55px;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	overflow-x: hidden;
}
.sidebar {
	margin-top: 55px;
}
.main {
	display: block;
	margin-left: 200px;
	flex: 1;
	min-width: 0;
	transition: 0.3s margin-left ease;

	&-sidebar-collapsed {
		margin-left: 37px;
	}
}
.container-fluid {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}
</style>
