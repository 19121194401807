var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("app-header"),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _c("app-sidebar", {
            staticClass: "sidebar",
            attrs: { sidebarList: _vm.nav },
            on: {
              "toggle-collapse": function ($event) {
                _vm.isSidebarCollapsed = $event
              },
            },
          }),
          _c(
            "main",
            {
              class: [
                "main",
                { "main-sidebar-collapsed": _vm.isSidebarCollapsed },
              ],
            },
            [
              _c(
                "b-container",
                { staticClass: "container-fluid", attrs: { fluid: "" } },
                [_c("router-view"), _c("app-modal")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }