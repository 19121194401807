var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.user.Login
        ? _c("span", { staticClass: "pr-2 login-name" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("welcome")))]),
            _vm._v("\n\t\t" + _vm._s(_vm.user.Login) + "\n\t"),
          ])
        : _vm._e(),
      _c("font-awesome-icon", {
        staticClass: "pointer",
        attrs: { icon: "sign-out-alt", size: "lg" },
        on: { click: _vm.logoutfnc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }