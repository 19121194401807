<template>
	<div class="header">
		<div class="d-flex flex-row align-items-center">
			<b-link class="brand" to="#">
				<img src="img/logo-sk.png" alt="Megaubytovanie" />
			</b-link>
			<quick-filter />
		</div>
		<div class="right-section">
			<language-switcher />
			<account />
		</div>
	</div>
</template>

<script>
import account from '../../components/common/account';
import languageSwitcher from '../../components/common/language-switcher';
import quickFilter from './quick-filter';

export default {
	name: 'app-header',
	components: {
		account,
		languageSwitcher,
		quickFilter,
	},
};
</script>
<style lang="scss" scoped>
.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 55px;
	padding: 0;
	margin: 0;
	background-color: var(--white);
	border-bottom: 1px solid #c8ced3;
	position: fixed;
	z-index: 1020;
	width: 100%;
	.brand {
		display: flex;
		align-items: center;
		> img {
			padding-left: 10px;
			height: 35%;
		}
	}
	.right-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 20px;
	}
}
</style>
