<template>
	<div class="language-switcher">
		<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none d-flex align-items-center toggler">
			<template slot="button-content">
				<span>{{ languagesName[selectedCode] }}</span>
				<label>&nbsp;</label>
				<img :src="languagesImage[selectedCode]" :alt="selectedCode" />
			</template>

			<b-dropdown-item v-for="langCode in languagesCode" :key="langCode" @click="changeLang(langCode)">
				<span>{{ languagesName[langCode] }}</span>
				<img :src="languagesImage[langCode]" :alt="langCode" />
			</b-dropdown-item>
		</b-dropdown>
		<b-dropdown
			size="lg"
			variant="link"
			:title="$t('selectcountry')"
			toggle-class="text-decoration-none d-flex align-items-center toggler"
		>
			<template slot="button-content">
				<span>{{ countriesName[selectedCountry] }}</span>
				<label>&nbsp;</label>
				<img :src="countryImage[selectedCountry]" :alt="selectedCountry" />
			</template>

			<b-dropdown-item v-for="countryCode in countryCode" :key="countryCode" @click="changeCountry(countryCode)">
				<span>{{ countriesName[countryCode] }}</span>
				<img :src="countryImage[countryCode]" :alt="countryCode" />
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>
<script>
import i18n from '@/services/lang';
import { loadLanguageTranslations } from '@/store/actions';
const globalCountryKey = 'global-country';

export default {
	name: 'LanguageSwitcher',
	data() {
		return {
			languagesCode: ['sk', 'cs', 'en'],
			countryCode: ['notSelected', '1', '2'],
			languagesImage: {
				sk: require('../../../public/img/icons/sk.png'),
				cs: require('../../../public/img/icons/cz.png'),
				en: require('../../../public/img/icons/en.png'),
			},
			languagesName: {
				sk: 'Slovenčina',
				cs: 'Čeština',
				en: 'Angličtina',
			},
			countriesName: {
				notSelected: this.$i18n.t('country2585'),
				1: this.$store.state.country.data[0].Name,
				2: this.$store.state.country.data[1].Name,
			},
			selectedCode: i18n.locale,
			selectedCountry: localStorage.getItem(globalCountryKey) || 'notSelected',
			countryImage: {
				notSelected: require('../../../public/img/icons/flag.png'),
				1: require('../../../public/img/icons/sk.png'),
				2: require('../../../public/img/icons/cz.png'),
			},
			countryName: {
				1: 'Slovensko',
				2: 'Česko',
			},
		};
	},
	methods: {
		changeLang(lang) {
			this.$store.dispatch(loadLanguageTranslations, lang).then(() => {
				this.selectedCode = lang;
			});
		},
		changeCountry(country) {
			if (country !== 'notSelected') {
				localStorage.setItem(globalCountryKey, country);
			} else {
				localStorage.removeItem(globalCountryKey);
			}

			this.selectedCountry = country;
			this.$router.go();
		},
	},
};
</script>
<style lang="scss">
.language-switcher {
	margin-right: 10px;
	.toggler::after {
		color: var(--black);
	}
	.dropdown-menu {
		.dropdown-item {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			min-width: initial;
			img {
				padding-right: 5px;
				padding-left: 5px;
				width: auto;
				height: 100%;
			}
			span {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}
</style>
