var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-row align-items-center" },
      [
        _c("b-link", { staticClass: "brand", attrs: { to: "#" } }, [
          _c("img", {
            attrs: { src: "img/logo-sk.png", alt: "Megaubytovanie" },
          }),
        ]),
        _c("quick-filter"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-section" },
      [_c("language-switcher"), _c("account")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }